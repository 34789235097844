.errPageWrapper
    max-width: 1040px
    margin: 0 auto

    height: 100vh

    display: flex
    flex-direction: column
    justify-content: center
    *
        text-transform: capitalize
        font-family: 'Poppins', sans-serif
    .errPageWrapper
        text-align: center
        h1
            color: #f5f5f5
        button
            background-color: #f5f5f5
            border-radius: 10px
            padding: 10px 30px
            color: #061E2E
            margin-top: 15px
            font-size: 18px
            align-self: center
            cursor: pointer
