.mainAbout {
  color: #f5f5f5;
  font-family: "Poppins", sans-serif;
  text-align: start;
  max-width: 50%;
}
@media screen and (max-width: 1190px) {
  .mainAbout {
    text-align: center;
    max-width: 90%;
  }
}
@media screen and (max-width: 730px) {
  .mainAbout {
    max-width: 70%;
  }
}
@media screen and (max-width: 530px) {
  .mainAbout {
    margin-top: 70px;
  }
}
.mainAbout * {
  font-weight: 400;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.mainAbout h2 {
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 20px;
}
@media screen and (max-width: 420px) {
  .mainAbout h2 {
    font-size: 7.143vw;
    line-height: 7.143vw;
    margin-bottom: 4.762vw;
  }
}
.mainAbout h2 span {
  font-size: 75px;
  line-height: 90px;
  margin-top: 20px;
  display: block;
  color: #F7DF1E;
}
@media screen and (max-width: 420px) {
  .mainAbout h2 span {
    font-size: 17.857vw;
    line-height: 21.429vw;
    margin-top: 4.762vw;
  }
}
.mainAbout p {
  font-size: 20px;
  line-height: 30px;
}
@media screen and (max-width: 420px) {
  .mainAbout p {
    font-size: 4.762vw;
    line-height: 7.143vw;
    width: 71.429vw;
  }
}/*# sourceMappingURL=About.css.map */