.aboutWrapper
    max-width: 1600px
    margin: 0 auto
    font-family: 'Poppins', sans-serif
    padding: 0 6.250vw
    .aboutRow
        h3
            margin-top: 35px
            font-size: 25px
            color: #F7DF1E
            font-weight: 400
        p
            margin: 15px 0
            color: #f5f5f5
            font-size: 18px
            line-height: 25px
        h3:first-child
            margin-top: 0
    .edWrapper, .expWrapper
        background-color: #0c2f46
        padding: 15px
        border-radius: 10px
        margin-top: 15px
        max-width: 1350px
        width: 100%
        position: relative
        h4
            color: #f5f5f5
            font-weight: normal
            display: inline-block
            font-size: 18px
        p
            display: block
            margin: 0
            margin-top: 10px
            font-size: 16px
            a
                color: #cdcdcd
            a:active
                color: #F7DF1E
        .p2
            margin: 0
            max-width: 1000px
        span
            float: right
            color: #f5f5f5
        .cp
            position: absolute
            right: 15px
            bottom: 15px
            color: #42c267