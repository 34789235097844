.detailedWrapper
    max-width: 1250px
    margin: 0 auto
    font-family: 'Poppins', sans-serif
    padding: 0 20px
    box-sizing: border-box
    h1
        text-align: center
        color: #f5f5f5
        margin-top: 60px
        font-size: 40px
        @media screen and ( max-width: 560px )
            margin-top: 10.714vw
            font-size: 7.143vw
    p
        font-size: 25px
        color: #f5f5f5
        width: 100%
        margin: 0 auto
        text-align: center
        margin-top: 20px
        @media screen and ( max-width: 560px )
            margin-top: 3.571vw
            font-size: 4.464vw
    h3
        margin-top: 35px
        font-weight: 400
        color: #F7DF1E
        font-size: 25px
        @media screen and ( max-width: 560px )
            margin-top: 6.250vw
            font-size: 4.464vw
    .features
        margin-top: 10px
        background-color: #0C2F46
        padding: 15px
        border-radius: 10px
        @media screen and ( max-width: 560px )
            margin-top: 1.786vw
            padding: 2.679vw
        li
            font-size: 18px
            color: #f5f5f5
            margin-left: 20px
            @media screen and ( max-width: 560px )
                font-size: 3.214vw
                margin-left: 3.571vw
    .builtWithList
        margin-top: 10px
        li
            display: inline
            vertical-align: middle
            a
                img
                    width: 55px
                    margin: 0 15px
                    @media screen and ( max-width: 420px )
                        width: 13.095vw
                        margin: 0 3.571vw
    .picturesWrapper
        margin-top: 10px
        img
            cursor: pointer
            object-fit: cover
            width: 100%
        .mainPicWrapper
            height: 500px
            overflow: hidden
            @media screen and ( max-width: 850px )
                height: 58.824vw
        .secondaryPics
            display: grid
            grid-template-columns: auto auto auto auto
            column-gap: 10px
            row-gap: 10px
            margin-top: 10px
            img
                width: 100%
                height: 250px
                @media screen and ( max-width: 1230px )
                    height: 20.325vw
    .displayImgWrapper
        position: fixed
        top: 0
        left: 0
        height: 100vh
        width: 100vw
        background-color: #0000007d
        .imgWrapper
            max-width: 1250px
            margin: 0 auto
            padding: 45px 0
            img
                width: 100%
                height: 100%
                max-height: 900px
    .htuWrapper
        a
            text-decoration: none
            text-align: start
            margin: 5px 0
            font-size: 16px
            cursor: pointer
            color: #f5f5f5
        a:hover
            color: #42c267
    .htuHTR
        h3
            margin: 0
            font-size: 20px
            margin-top: 5px
    .htuHTR
        li
            margin: 5px 0
            margin-left: 20px
            color: #f5f5f5
            span
                background-color: #0C2F46
                border-radius: 10px
                padding: 5px
.backArrow
    fill: #F7DF1E
    transform: scale(1.5)
    position: absolute
    left: 50px
    cursor: pointer
    @media screen and ( max-width: 560px )
        left: 8.929vw
    @media screen and ( max-width: 420px )
        transform: scale(1.3)
    @media screen and ( max-width: 325px )
        transform: scale(1)