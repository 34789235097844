.FMDisplayWrapper {
  position: relative;
  height: 300px;
  min-width: 300px;
  width: 700px;
}
@media screen and (max-width: 800px) {
  .FMDisplayWrapper {
    width: 87.5vw;
  }
}
@media screen and (max-width: 535px) {
  .FMDisplayWrapper {
    position: fixed;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
    top: 12%;
    right: 0;
    min-width: unset;
    z-index: 20;
  }
}
.FMDisplayWrapper * {
  cursor: pointer;
}
.FMDisplayWrapper .svgWrapper {
  position: absolute;
  transition: all 0.3s ease-in-out;
  z-index: 10;
}
.FMDisplayWrapper .svgWrapper svg {
  pointer-events: none;
}
.FMDisplayWrapper .svgWrapper .FMReact {
  filter: grayscale(100%);
  width: 180px;
  height: 180px;
}
.FMDisplayWrapper .svgWrapper .FMVue {
  filter: grayscale(100%);
  opacity: 0.3;
  width: 180px;
  height: 180px;
}
.FMDisplayWrapper .svgWrapper .FMVue:hover {
  filter: grayscale(80%);
}
.FMDisplayWrapper .svgWrapper .FMJs {
  filter: grayscale(100%);
  opacity: 0.3;
  width: 150px;
  height: 150px;
}
.FMDisplayWrapper .svgWrapper .FMJs:hover {
  filter: grayscale(80%);
}
.FMDisplayWrapper .svgWrapper.active {
  top: 75% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: unset !important;
}
.FMDisplayWrapper .svgWrapper.active svg {
  width: 250px;
  filter: grayscale(0%);
  opacity: 1;
}
.FMDisplayWrapper .svgWrapper:nth-child(1) {
  top: 30%;
  transform: translate(-50%, -50%);
}
.FMDisplayWrapper .svgWrapper:nth-child(2) {
  top: 30%;
  left: 25%;
  transform: translate(-50%, -50%);
  width: 180px;
}
.FMDisplayWrapper .svgWrapper:nth-child(3) {
  top: 30%;
  left: 79%;
  transform: translate(-50%, -50%);
  width: 150px;
}
.FMDisplayWrapper .svgBox {
  background-color: #0d3047;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 7px;
  margin: 10px 0;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease-in-out;
  border-radius: 15px 0 0 15px;
  padding-left: 10px;
}
.FMDisplayWrapper .svgBox svg {
  width: 30px;
  height: 30px;
  pointer-events: none;
}
.FMDisplayWrapper .svgBox.active {
  background-color: #194e71;
}/*# sourceMappingURL=FMDisplay.css.map */