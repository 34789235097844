.FMDisplayWrapper
    position: relative
    height: 300px
    min-width: 300px
    width: 700px
    @media screen and ( max-width: 800px )
        width: 87.500vw
    @media screen and ( max-width: 535px )
        position: fixed
        width: fit-content
        height: auto
        top: 12%
        right: 0
        min-width: unset
        z-index: 20
    *
        cursor: pointer
    .svgWrapper
        position: absolute
        transition: all .3s ease-in-out
        z-index: 10
        svg
            pointer-events: none
        .FMReact
            filter: grayscale(100%)
            width: 180px
            height: 180px
        .FMVue
            filter: grayscale(100%)
            opacity: .3
            width: 180px
            height: 180px
        .FMVue:hover
            filter: grayscale(80%)
        .FMJs
            filter: grayscale(100%)
            opacity: .3
            width: 150px
            height: 150px
        .FMJs:hover
            filter: grayscale(80%)
    .svgWrapper
        &.active
            top: 75% !important
            left: 50% !important
            transform: translate(-50%, -50%) !important
            width: unset !important
            svg
                width: 250px
                filter: grayscale(0%)
                opacity: 1
    .svgWrapper:nth-child(1)
        top: 30%
        transform: translate(-50%, -50%)
    .svgWrapper:nth-child(2)
        top: 30%
        left: 25%
        transform: translate(-50%, -50%)
        width: 180px
    .svgWrapper:nth-child(3)
        top: 30%
        left: 79%
        transform: translate(-50%, -50%)
        width: 150px
    .svgBox
        background-color: #0d3047
        max-width: fit-content
        padding: 7px
        margin: 10px 0
        vertical-align: middle
        display: flex
        justify-content: center
        align-items: center
        transition: background-color .3s ease-in-out
        border-radius: 15px 0 0 15px
        padding-left: 10px
        svg
            width: 30px
            height: 30px
            pointer-events: none
        &.active
            background-color: #194e71