.aboutWrapper {
  max-width: 1600px;
  margin: 0 auto;
  font-family: "Poppins", sans-serif;
  padding: 0 6.25vw;
}
.aboutWrapper .aboutRow h3 {
  margin-top: 35px;
  font-size: 25px;
  color: #F7DF1E;
  font-weight: 400;
}
.aboutWrapper .aboutRow p {
  margin: 15px 0;
  color: #f5f5f5;
  font-size: 18px;
  line-height: 25px;
}
.aboutWrapper .aboutRow h3:first-child {
  margin-top: 0;
}
.aboutWrapper .edWrapper, .aboutWrapper .expWrapper {
  background-color: #0c2f46;
  padding: 15px;
  border-radius: 10px;
  margin-top: 15px;
  max-width: 1350px;
  width: 100%;
  position: relative;
}
.aboutWrapper .edWrapper h4, .aboutWrapper .expWrapper h4 {
  color: #f5f5f5;
  font-weight: normal;
  display: inline-block;
  font-size: 18px;
}
.aboutWrapper .edWrapper p, .aboutWrapper .expWrapper p {
  display: block;
  margin: 0;
  margin-top: 10px;
  font-size: 16px;
}
.aboutWrapper .edWrapper p a, .aboutWrapper .expWrapper p a {
  color: #cdcdcd;
}
.aboutWrapper .edWrapper p a:active, .aboutWrapper .expWrapper p a:active {
  color: #F7DF1E;
}
.aboutWrapper .edWrapper .p2, .aboutWrapper .expWrapper .p2 {
  margin: 0;
  max-width: 1000px;
}
.aboutWrapper .edWrapper span, .aboutWrapper .expWrapper span {
  float: right;
  color: #f5f5f5;
}
.aboutWrapper .edWrapper .cp, .aboutWrapper .expWrapper .cp {
  position: absolute;
  right: 15px;
  bottom: 15px;
  color: #42c267;
}/*# sourceMappingURL=AboutMe.css.map */