.detailedWrapper {
  max-width: 1250px;
  margin: 0 auto;
  font-family: "Poppins", sans-serif;
  padding: 0 20px;
  box-sizing: border-box;
}
.detailedWrapper h1 {
  text-align: center;
  color: #f5f5f5;
  margin-top: 60px;
  font-size: 40px;
}
@media screen and (max-width: 560px) {
  .detailedWrapper h1 {
    margin-top: 10.714vw;
    font-size: 7.143vw;
  }
}
.detailedWrapper p {
  font-size: 25px;
  color: #f5f5f5;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-top: 20px;
}
@media screen and (max-width: 560px) {
  .detailedWrapper p {
    margin-top: 3.571vw;
    font-size: 4.464vw;
  }
}
.detailedWrapper h3 {
  margin-top: 35px;
  font-weight: 400;
  color: #F7DF1E;
  font-size: 25px;
}
@media screen and (max-width: 560px) {
  .detailedWrapper h3 {
    margin-top: 6.25vw;
    font-size: 4.464vw;
  }
}
.detailedWrapper .features {
  margin-top: 10px;
  background-color: #0C2F46;
  padding: 15px;
  border-radius: 10px;
}
@media screen and (max-width: 560px) {
  .detailedWrapper .features {
    margin-top: 1.786vw;
    padding: 2.679vw;
  }
}
.detailedWrapper .features li {
  font-size: 18px;
  color: #f5f5f5;
  margin-left: 20px;
}
@media screen and (max-width: 560px) {
  .detailedWrapper .features li {
    font-size: 3.214vw;
    margin-left: 3.571vw;
  }
}
.detailedWrapper .builtWithList {
  margin-top: 10px;
}
.detailedWrapper .builtWithList li {
  display: inline;
  vertical-align: middle;
}
.detailedWrapper .builtWithList li a img {
  width: 55px;
  margin: 0 15px;
}
@media screen and (max-width: 420px) {
  .detailedWrapper .builtWithList li a img {
    width: 13.095vw;
    margin: 0 3.571vw;
  }
}
.detailedWrapper .picturesWrapper {
  margin-top: 10px;
}
.detailedWrapper .picturesWrapper img {
  cursor: pointer;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}
.detailedWrapper .picturesWrapper .mainPicWrapper {
  height: 500px;
  overflow: hidden;
}
@media screen and (max-width: 850px) {
  .detailedWrapper .picturesWrapper .mainPicWrapper {
    height: 58.824vw;
  }
}
.detailedWrapper .picturesWrapper .secondaryPics {
  display: grid;
  grid-template-columns: auto auto auto auto;
  -moz-column-gap: 10px;
       column-gap: 10px;
  row-gap: 10px;
  margin-top: 10px;
}
.detailedWrapper .picturesWrapper .secondaryPics img {
  width: 100%;
  height: 250px;
}
@media screen and (max-width: 1230px) {
  .detailedWrapper .picturesWrapper .secondaryPics img {
    height: 20.325vw;
  }
}
.detailedWrapper .displayImgWrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4901960784);
}
.detailedWrapper .displayImgWrapper .imgWrapper {
  max-width: 1250px;
  margin: 0 auto;
  padding: 45px 0;
}
.detailedWrapper .displayImgWrapper .imgWrapper img {
  width: 100%;
  height: 100%;
  max-height: 900px;
}
.detailedWrapper .htuWrapper a {
  text-decoration: none;
  text-align: start;
  margin: 5px 0;
  font-size: 16px;
  cursor: pointer;
  color: #f5f5f5;
}
.detailedWrapper .htuWrapper a:hover {
  color: #42c267;
}
.detailedWrapper .htuHTR h3 {
  margin: 0;
  font-size: 20px;
  margin-top: 5px;
}
.detailedWrapper .htuHTR li {
  margin: 5px 0;
  margin-left: 20px;
  color: #f5f5f5;
}
.detailedWrapper .htuHTR li span {
  background-color: #0C2F46;
  border-radius: 10px;
  padding: 5px;
}

.backArrow {
  fill: #F7DF1E;
  transform: scale(1.5);
  position: absolute;
  left: 50px;
  cursor: pointer;
}
@media screen and (max-width: 560px) {
  .backArrow {
    left: 8.929vw;
  }
}
@media screen and (max-width: 420px) {
  .backArrow {
    transform: scale(1.3);
  }
}
@media screen and (max-width: 325px) {
  .backArrow {
    transform: scale(1);
  }
}/*# sourceMappingURL=ProjectDetailed.css.map */