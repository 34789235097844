.vueWrapper {
  max-width: 1600px;
  margin: 90px auto;
  padding: 0 120px;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
  row-gap: 40px;
  -moz-column-gap: 20px;
       column-gap: 20px;
  overflow: hidden;
}
@media screen and (max-width: 1500px) {
  .vueWrapper {
    grid-template-columns: repeat(auto-fill, 400px);
    justify-content: center;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 1075px) {
  .vueWrapper {
    grid-template-columns: repeat(auto-fill, 80vw);
  }
}
@media screen and (max-width: 740px) {
  .vueWrapper {
    padding: 0;
  }
}
@media screen and (max-width: 535px) {
  .vueWrapper {
    margin-top: 30px;
  }
}
.vueWrapper * {
  font-family: "Poppins", sans-serif;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.vueWrapper .vueApp a {
  text-transform: none;
  text-decoration: none;
}
.vueWrapper .vueApp .imgWrapper {
  width: 400px;
  height: 250px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 5px;
}
@media screen and (max-width: 1075px) {
  .vueWrapper .vueApp .imgWrapper {
    width: 100%;
  }
}
.vueWrapper .vueApp .imgWrapper img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}
.vueWrapper .vueApp .imgWrapper:hover img {
  transform: scale(1.1);
}
.vueWrapper .vueApp h3 {
  margin-top: 20px;
  font-weight: 400;
  color: #F7DF1E;
}
.vueWrapper .vueApp p {
  font-size: 17px;
  color: #f5f5f5;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}/*# sourceMappingURL=VueProjects.css.map */