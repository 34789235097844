.vueWrapper
    max-width: 1600px
    margin: 90px auto
    padding: 0 120px
    display: grid
    grid-template-columns: auto auto auto
    justify-content: space-between
    row-gap: 40px
    column-gap: 20px
    overflow: hidden
    @media screen and ( max-width: 1500px )
        grid-template-columns: repeat(auto-fill, 400px)
        justify-content: center
        margin-bottom: 30px
    @media screen and ( max-width: 1075px )
        grid-template-columns: repeat(auto-fill, 80vw)
    @media screen and ( max-width: 740px )
        padding: 0
    @media screen and ( max-width: 535px )
        margin-top: 30px
    *
        font-family: 'Poppins', sans-serif
        user-select: none
    .vueApp
        a
            text-transform: none
            text-decoration: none
        .imgWrapper
            width: 400px
            height: 250px
            cursor: pointer
            overflow: hidden
            border-radius: 5px
            @media screen and ( max-width: 1075px )
                width: 100%
            img
                width: 100%
                height: 100%
                object-fit: cover
                border-radius: 5px
                transition: all .3s ease-in-out
        .imgWrapper:hover
            img
                transform: scale(1.1)
        h3
            margin-top: 20px
            font-weight: 400
            color: #F7DF1E
        p
            font-size: 17px
            color: #f5f5f5
            max-width: fit-content
