.navbarWrapper {
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  padding: 25px 0;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1450980392);
  background-color: #061E2E;
  display: flex;
  align-items: center;
  z-index: 10;
}
@media screen and (max-width: 1650px) {
  .navbarWrapper {
    position: unset;
    height: unset;
    left: unset;
    top: unset;
    justify-content: center;
  }
}
.navbarWrapper * {
  font-family: "Poppins", sans-serif;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.navbarWrapper ul {
  text-align: center;
  margin: 0 25px;
}
.navbarWrapper ul li {
  list-style: none;
  margin: 50px 0;
}
@media screen and (max-width: 1650px) {
  .navbarWrapper ul li {
    display: inline;
    padding: 0 25px;
  }
}
.navbarWrapper ul a {
  text-decoration: none;
  color: #F7DF1E;
}
.navbarWrapper ul a.active {
  padding-bottom: 10px;
  border-bottom: 1px solid #F7DF1E;
}
.navbarWrapper.mobile {
  display: flex;
  justify-content: space-between;
  padding: 25px 30px;
  position: fixed !important;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 30;
  transition: padding 0.3s ease-in-out;
}
.navbarWrapper.shrinked {
  padding: 5px 30px;
}
.navbarWrapper .burger {
  width: 30px;
  height: 30px;
  fill: #F7DF1E;
  cursor: pointer;
}
.navbarWrapper h1 {
  color: #F7DF1E;
  cursor: pointer;
  font-weight: 500;
}
.navbarWrapper .burgerWrapper {
  position: absolute;
  width: 250px;
  background-color: #0b293e;
  box-shadow: 0 0 0 100vw rgba(7, 28, 42, 0.4549019608);
  right: -250px;
  top: 0;
  z-index: 40;
  transition: right 0.5s ease-in-out;
  height: 100vh;
}
.navbarWrapper .burgerWrapper svg {
  position: absolute;
  right: 15px;
  top: 15px;
  fill: #F7DF1E;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.navbarWrapper .burgerWrapper .scrollTo {
  margin-top: 30px;
  padding: 20px;
}
.navbarWrapper .burgerWrapper .scrollTo h3 {
  font-size: 20px;
  font-weight: 400;
  color: #F7DF1E;
  margin-top: 15px;
}
.navbarWrapper .burgerWrapper .scrollTo ul {
  margin: 5px 0;
  text-align: left;
  margin-left: 5px;
}
.navbarWrapper .burgerWrapper .scrollTo ul li {
  display: list-item !important;
  margin: 5px 0;
  padding: 0;
}
.navbarWrapper .burgerWrapper .scrollTo ul a {
  color: #F7DF1E;
}
.navbarWrapper .burgerWrapper .scrollTo ul a:active {
  color: #b2ff66;
}
.navbarWrapper .burgerWrapper .contactWrapper h3, .navbarWrapper .burgerWrapper .contactWrapper p:nth-child(1) {
  display: none;
}
.navbarWrapper .burgerWrapper .contactWrapper .contactParent div a svg {
  width: 24px;
  height: 24px;
  position: unset;
}
.navbarWrapper .burgerWrapper .contactWrapper .contactParent span {
  left: 70px;
  top: 60px;
}
.navbarWrapper .burgerWrapper .dlResume {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
  text-decoration: none;
  padding: unset;
  margin-top: 45px;
}
.navbarWrapper .burgerWrapper .dlResume svg {
  position: unset !important;
  width: unset;
  height: unset;
  transform: scale(1.5);
}
.navbarWrapper .burgerWrapper .dlResume p {
  font-size: 18px;
  margin-top: 15px;
  color: #F7DF1E;
}/*# sourceMappingURL=Navbar.css.map */