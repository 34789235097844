.skillsWrapper
    max-width: 1600px
    margin: 0 auto
    font-family: 'Poppins', sans-serif
    padding: 0 120px
    @media screen and ( max-width: 835px )
        padding: 0
        max-width: 820px
        text-align: center
    h3
        margin-top: 35px
        font-size: 25px
        color: #F7DF1E
        font-weight: 400
    .skillWrapper, .languageWrapper
        display: grid
        grid-template-columns: repeat(3, auto)
        column-gap: 40px
        row-gap: 10px
        margin-top: 15px
        @media screen and ( max-width: 1200px )
            grid-template-columns: repeat(auto-fill, 270px)
            justify-content: space-between
        @media screen and ( max-width: 835px )
            grid-template-columns: repeat(auto-fill, 68.862vw)
            justify-content: center
        .skillHolder, .languageHolder
            display: flex
            justify-content: space-between
            align-items: center
            p
                color: #f5f5f5
                font-size: 16px
            p:nth-child(2n)
                color: #cdcdcd
