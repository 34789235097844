.navbarWrapper
    height: 100vh
    position: fixed
    left: 0
    top: 0
    padding: 25px 0
    box-shadow: 0 0 30px #00000025
    background-color: #061E2E

    display: flex
    align-items: center
    z-index: 10
    @media screen and ( max-width: 1650px )
        position: unset
        height: unset
        left: unset
        top: unset

        justify-content: center
    *
        font-family: 'Poppins', sans-serif
        user-select: none
    ul
        text-align: center
        margin: 0 25px
        li
            list-style: none
            margin: 50px 0
            @media screen and ( max-width: 1650px )
                display: inline
                padding: 0 25px
        a
            text-decoration: none
            color: #F7DF1E
            &.active
                padding-bottom: 10px
                border-bottom: 1px solid #F7DF1E
    &.mobile
        display: flex
        justify-content: space-between
        padding: 25px 30px
        position: fixed !important
        width: 100%
        top: 0
        left: 0
        z-index: 30
        transition: padding .3s ease-in-out
    &.shrinked
        padding: 5px 30px
    .burger
        width: 30px
        height: 30px
        fill: #F7DF1E
        cursor: pointer
    h1
        color: #F7DF1E
        cursor: pointer
        font-weight: 500
    .burgerWrapper
        position: absolute
        width: 250px
        background-color: #0b293e
        box-shadow: 0 0 0 100vw #071c2a74
        right: -250px
        top: 0
        z-index: 40
        transition: right .5s ease-in-out
        height: 100vh
        svg
            position: absolute
            right: 15px
            top: 15px          
            fill: #F7DF1E
            width: 30px
            height: 30px
            cursor: pointer
        .scrollTo
            margin-top: 30px
            padding: 20px
            h3
                font-size: 20px
                font-weight: 400
                color: #F7DF1E
                margin-top: 15px
            ul
                margin: 5px 0
                text-align: left
                margin-left: 5px
                li
                    display: list-item !important
                    margin: 5px 0
                    padding: 0
                a
                    color: #F7DF1E
                a:active
                    color: #b2ff66
        .contactWrapper
            h3, p:nth-child(1)
                display: none
            .contactParent
                div
                    a
                        svg
                            width: 24px
                            height: 24px
                            position: unset
                span
                    left: 70px
                    top: 60px
        .dlResume
            display: flex
            flex-direction: column
            align-items: center
            margin-top: 35px
            text-decoration: none
            padding: unset
            margin-top: 45px
            svg
                position: unset !important
                width: unset
                height: unset
                transform: scale(1.5)
            p
                font-size: 18px
                margin-top: 15px
                color: #F7DF1E