.contactWrapper {
  max-width: 1600px;
  margin: 0 auto;
  font-family: "Poppins", sans-serif;
  padding: 0 120px;
  margin-bottom: 40px;
  position: relative;
}
@media screen and (max-width: 835px) {
  .contactWrapper {
    padding: 0;
    margin: 0 auto;
    text-align: center;
  }
}
.contactWrapper h3 {
  margin-top: 35px;
  font-size: 25px;
  color: #F7DF1E;
  font-weight: 400;
}
.contactWrapper .contactParent {
  display: flex;
  justify-content: flex-start;
  margin-top: 15px;
  -moz-column-gap: 12.5px;
       column-gap: 12.5px;
}
@media screen and (max-width: 835px) {
  .contactWrapper .contactParent {
    justify-content: center;
  }
}
@media screen and (max-width: 595px) {
  .contactWrapper .contactParent {
    flex-direction: column;
    row-gap: 10px;
  }
  .contactWrapper .contactParent div {
    margin: 0 auto;
  }
}
.contactWrapper .contactParent div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.contactWrapper .contactParent div p {
  display: inline-block;
  color: #f5f5f5;
  font-size: 16px;
}
.contactWrapper .contactParent a {
  line-height: 10px;
}
.contactWrapper .contactParent a svg {
  fill: #F7DF1E;
  cursor: pointer;
  width: 25px;
  height: 25px;
}
.contactWrapper .contactParent .email {
  align-items: flex-end;
  color: #cdcdcd;
  font-size: 14px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.contactWrapper .contactParent span {
  color: #42c267;
  overflow: hidden;
  -webkit-animation: copy 0.1s ease-in-out forwards;
          animation: copy 0.1s ease-in-out forwards;
  position: absolute;
  left: 550px;
  top: 65px;
  white-space: nowrap;
}
@media screen and (max-width: 595px) {
  .contactWrapper .contactParent span {
    left: 315px;
    top: 110px;
  }
}
.contactWrapper .contactParent span.mad {
  color: #c24242;
  -webkit-animation: copy 0.1s ease-in-out forwards, shake 0.1s forwards infinite;
          animation: copy 0.1s ease-in-out forwards, shake 0.1s forwards infinite;
}
.contactWrapper .contactParent span.max {
  color: #d23535;
  -webkit-animation: copy 0.1s ease-in-out forwards, shake 0.1s forwards infinite;
          animation: copy 0.1s ease-in-out forwards, shake 0.1s forwards infinite;
}

@-webkit-keyframes copy {
  0% {
    width: 0px;
  }
  100% {
    width: 150px;
  }
}

@keyframes copy {
  0% {
    width: 0px;
  }
  100% {
    width: 150px;
  }
}
@-webkit-keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-1deg);
  }
  20% {
    transform: rotate(1deg);
  }
  30% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(1deg);
  }
  50% {
    transform: rotate(-1deg);
  }
  60% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(-1deg);
  }
  80% {
    transform: trotate(1deg);
  }
  90% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-1deg);
  }
}
@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-1deg);
  }
  20% {
    transform: rotate(1deg);
  }
  30% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(1deg);
  }
  50% {
    transform: rotate(-1deg);
  }
  60% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(-1deg);
  }
  80% {
    transform: trotate(1deg);
  }
  90% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-1deg);
  }
}/*# sourceMappingURL=Contact.css.map */