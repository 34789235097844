.footerWrapper
    max-width: 1600px
    margin: 0 auto
    font-family: "Poppins", sans-serif
    padding: 0 120px
    margin-bottom: 30px
    margin-top: 50px
    .footerHR
        border: 1px solid #f5f5f5
        width: 100%
        margin: 15px auto
    .footerDiv
        display: flex
        justify-content: space-between
        align-items: center
        @media screen and ( max-width: 575px )
            flex-direction: column
            text-align: center
        a, p
            display: block
            text-decoration: none
            margin: 5px 0
            color: #f5f5f5
            font-size: 18px
        a:first-child
            @media screen and ( max-width: 575px )
                margin: 0
        a:hover
            color: #42c267
        .location
            @media screen and ( max-width: 575px )
                p
                    display: none
