.contactWrapper
    max-width: 1600px
    margin: 0 auto
    font-family: 'Poppins', sans-serif
    padding: 0 120px
    margin-bottom: 40px
    position: relative
    @media screen and ( max-width: 835px )
        padding: 0
        margin: 0 auto
        text-align: center
    h3
        margin-top: 35px
        font-size: 25px
        color: #F7DF1E
        font-weight: 400
    .contactParent
        display: flex
        justify-content: flex-start
        margin-top: 15px
        column-gap: 12.5px
        @media screen and ( max-width: 835px )
            justify-content: center
        @media screen and ( max-width: 595px )
            flex-direction: column
            row-gap: 10px
            div
                margin: 0 auto
        div
            display: flex
            justify-content: flex-start
            align-items: center
            column-gap: 10px
            p
                display: inline-block
                color: #f5f5f5
                font-size: 16px
        a
            line-height: 10px
            svg
                fill: #F7DF1E
                cursor: pointer
                width: 25px
                height: 25px
        .email
            align-items: flex-end
            color: #cdcdcd
            font-size: 14px
            cursor: pointer
            user-select: none
        span
            color: #42c267
            overflow: hidden
            animation: copy .1s ease-in-out forwards

            position: absolute
            left: 550px
            top: 65px
            white-space: nowrap
            @media screen and ( max-width: 595px )
                left: 315px
                top: 110px
            &.mad
                color: #c24242
                animation: copy .1s ease-in-out forwards, shake .1s forwards infinite
            &.max
                color: #d23535
                animation: copy .1s ease-in-out forwards, shake .1s forwards infinite
@keyframes copy
    0%
        width: 0px
    100%
        width: 150px

@keyframes shake
    0% 
        transform: rotate(0deg)
    10% 
        transform: rotate(-1deg)
    20% 
        transform: rotate(1deg)
    30% 
        transform: rotate(0deg)
    40% 
        transform: rotate(1deg)
    50% 
        transform: rotate(-1deg)
    60% 
        transform: rotate(0deg)
    70% 
        transform: rotate(-1deg)
    80% 
        transform: trotate(1deg)
    90% 
        transform: rotate(0deg)
    100% 
        transform: rotate(-1deg)