.mainWrapper {
  max-width: 1480px;
  margin: 0 auto;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-column-gap: 20vw;
       column-gap: 20vw;
  padding: 0 120px;
}
@media screen and (max-width: 1190px) {
  .mainWrapper {
    flex-direction: column;
    row-gap: 3vw;
  }
}
@media screen and (max-width: 730px) {
  .mainWrapper {
    padding: 0;
  }
}

.divisor {
  border: 1px solid #f5f5f5;
  max-width: 1350px;
  margin: 15px auto;
  margin-bottom: 50px;
}
@media screen and (max-width: 1390px) {
  .divisor {
    display: none;
  }
}

main .dlResume {
  max-width: 1600px;
  margin: 0 auto;
  font-family: "Poppins", sans-serif;
  padding: 0 120px;
  margin-bottom: 60px;
  text-decoration: none;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  -moz-column-gap: 20px;
       column-gap: 20px;
}
main .dlResume p {
  font-size: 25px;
  color: #F7DF1E;
  font-weight: 400;
  display: inline-block;
}
main .dlResume svg {
  transform: scale(1.5);
  fill: #F7DF1E;
}/*# sourceMappingURL=Home.css.map */