.footerWrapper {
  max-width: 1600px;
  margin: 0 auto;
  font-family: "Poppins", sans-serif;
  padding: 0 120px;
  margin-bottom: 30px;
  margin-top: 50px;
}
.footerWrapper .footerHR {
  border: 1px solid #f5f5f5;
  width: 100%;
  margin: 15px auto;
}
.footerWrapper .footerDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 575px) {
  .footerWrapper .footerDiv {
    flex-direction: column;
    text-align: center;
  }
}
.footerWrapper .footerDiv a, .footerWrapper .footerDiv p {
  display: block;
  text-decoration: none;
  margin: 5px 0;
  color: #f5f5f5;
  font-size: 18px;
}
@media screen and (max-width: 575px) {
  .footerWrapper .footerDiv a:first-child {
    margin: 0;
  }
}
.footerWrapper .footerDiv a:hover {
  color: #42c267;
}
@media screen and (max-width: 575px) {
  .footerWrapper .footerDiv .location p {
    display: none;
  }
}/*# sourceMappingURL=Footer.css.map */