.skillsWrapper {
  max-width: 1600px;
  margin: 0 auto;
  font-family: "Poppins", sans-serif;
  padding: 0 120px;
}
@media screen and (max-width: 835px) {
  .skillsWrapper {
    padding: 0;
    max-width: 820px;
    text-align: center;
  }
}
.skillsWrapper h3 {
  margin-top: 35px;
  font-size: 25px;
  color: #F7DF1E;
  font-weight: 400;
}
.skillsWrapper .skillWrapper, .skillsWrapper .languageWrapper {
  display: grid;
  grid-template-columns: repeat(3, auto);
  -moz-column-gap: 40px;
       column-gap: 40px;
  row-gap: 10px;
  margin-top: 15px;
}
@media screen and (max-width: 1200px) {
  .skillsWrapper .skillWrapper, .skillsWrapper .languageWrapper {
    grid-template-columns: repeat(auto-fill, 270px);
    justify-content: space-between;
  }
}
@media screen and (max-width: 835px) {
  .skillsWrapper .skillWrapper, .skillsWrapper .languageWrapper {
    grid-template-columns: repeat(auto-fill, 68.862vw);
    justify-content: center;
  }
}
.skillsWrapper .skillWrapper .skillHolder, .skillsWrapper .skillWrapper .languageHolder, .skillsWrapper .languageWrapper .skillHolder, .skillsWrapper .languageWrapper .languageHolder {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.skillsWrapper .skillWrapper .skillHolder p, .skillsWrapper .skillWrapper .languageHolder p, .skillsWrapper .languageWrapper .skillHolder p, .skillsWrapper .languageWrapper .languageHolder p {
  color: #f5f5f5;
  font-size: 16px;
}
.skillsWrapper .skillWrapper .skillHolder p:nth-child(2n), .skillsWrapper .skillWrapper .languageHolder p:nth-child(2n), .skillsWrapper .languageWrapper .skillHolder p:nth-child(2n), .skillsWrapper .languageWrapper .languageHolder p:nth-child(2n) {
  color: #cdcdcd;
}/*# sourceMappingURL=MySkills.css.map */